import styled from "@emotion/styled"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"
import { handleUrlPrefix } from "../../../utils/translations-util"
import { getGatsbyImageBunnySrc } from "../../../utils/bunny-cdn"

const Content = styled.div`
  ${(props) => props.theme.breakpointsLegacy.desktop} {
    width: 100%;
    margin: 0 auto;
    padding: 0 107px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    column-gap: 1em;
    row-gap: 1em;
  }
`

const PostImg = styled(GatsbyImage)`
  width: 100%;
  flex: 1;
  aspect-ratio: 16 / 10;
`

const PostTile = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};

  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  .details {
    margin-top: 12px;
    width: 100%;
    text-align: left;
    font-size: 12px;

    .title {
      font-weight: bold;
    }

    .description {
      display: block;
    }

    .date {
      display: none;
    }
    .comma {
      display: none;
    }
  }

  &.highlight {
    .details {
      .title {
        display: inline;
      }

      .author {
        display: inline;
      }
    }
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;

    .details {
      padding-right: 10px;
      font-size: 14px;

      .title-author {
        display: flex;
        flex-wrap: wrap;
      }

      .title {
        display: block;
      }

      .description {
        display: none;
      }

      .date {
        display: inline-block;
      }
      .comma {
        display: inline;
      }
    }

    &.highlight {
      display: flex;
      flex-direction: row-reverse;
      grid-column-end: span 2;

      .details {
        margin-top: 0px;
        width: 40%;
        margin-bottom: 5px;
        line-height: 1.21;

        .title {
          display: inline-block;
        }

        .author {
          font-weight: 300;
        }

        .description {
          display: block;
        }
      }
    }
  }

  ${(props) => props.theme.breakpointsLegacy.hd} {
    .details {
      font-size: 16px;
    }
  }
`

const PostDate = styled.div`
  display: block;
  font-size: 8px;
  line-height: 1.38;
  margin-top: 12px;
  text-transform: uppercase;

  .version {
    display: inline-block;
    width: 25px;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    display: none;
  }
`

const JournalGrid = ({ data, locale }) => {
  const sortedProjects = data?.posts?.edges?.sort((a, b) => {
    return new Date(b.node.date) - new Date(a.node.date)
  })

  return (
    <Content>
      {sortedProjects?.map(({ node }, i) => {
        const { height, width } = node.featuredImage.gatsbyImageData
        const image = node.featuredImage
        if (i === 0) {
          return (
            <PostTile
              key={node.id}
              className="highlight"
              to={handleUrlPrefix(locale, `/projects/${node.slug}`)}
              isLandscape={width > height}
            >
              <PostImg
                image={getGatsbyImageBunnySrc(image.gatsbyImageData)}
                imgStyle={{ objectFit: "cover" }}
                alt={image.title}
              />
              <PostDate>
                <span className="version">{i + 1}</span>
                <span>{node.date}</span>
              </PostDate>
              <div className="details">
                <div className="title-author">
                  <div className="title">{node.title}&nbsp;</div>
                  {node.author && (
                    <div className="author">
                      {node.author}
                      <span className="comma">,</span>{" "}
                    </div>
                  )}
                </div>
                <span className="description">{node.description}</span>
                <span className="date">{node.date}</span>
              </div>
            </PostTile>
          )
        }
        return (
          <PostTile
            key={node.id}
            to={handleUrlPrefix(locale, `/projects/${node.slug}`)}
            isLandscape={width > height}
          >
            <PostImg
              image={getGatsbyImageBunnySrc(image.gatsbyImageData)}
              imgStyle={{ objectFit: "cover" }}
              alt={image.title}
            />
            <PostDate>
              <span className="version">{i + 1}</span>
              <span>{node.date}</span>
            </PostDate>
            <div className="details">
              <span className="title">{node.title}&nbsp;</span>
              {node.author && (
                <span className="author">
                  {node.author}
                  <span className="comma">,</span>{" "}
                </span>
              )}
              <span className="description">{node.description}</span>
              <span className="date">{node.date}</span>
            </div>
          </PostTile>
        )
      })}
    </Content>
  )
}
export default JournalGrid
